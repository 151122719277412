

.airplane__name-3SqFH {
	font-weight: 400;
	font-size: 13px;
}

.airplane__name-3SqFH a {
	text-decoration: none;
}
