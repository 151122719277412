

div.button-3yw0L,
button.button-3yw0L {
	border-radius: var(--button-flat-border-radius);

	background-color: var(--brand-3-0);
}

button.button-3yw0L {
	border-radius: 6px;
}

div.button-3yw0L,
button.button-3yw0L:hover {
	background-color: var(--brand-3-1);
}

button.backButton-1S0p3 {
	flex-shrink: 0;
	min-width: 40px;
	height: 40px;
	margin-right: 10px;
	padding: 0;

	border-radius: 50%;

	color: var(--text-pale);

	background-color: var(--bg-fill);
}

button.backButton-1S0p3:hover {
	background-color: var(--gray05);
}

.toolbar-tq2rq {
	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
}

.price-1Qdvh {
	color: var(--text-base);
	font-weight: 700;
	line-height: 1.3;
}
