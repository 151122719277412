

.element-3yjYy {
	border-color: var(--brand-1-3);

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.element_active-3Cu4c {
	z-index: 1;

	color: var(--white);

	background: var(--brand-1-0);
}
