button.fareGroup__button-382sp {
	min-height: 46px;
	padding: 0 16px;

	border: none;
	border-radius: 40px;

	color: var(--text-base);
	font-weight: 500;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	background: var(--white);

	transition: color 0.15s, background 0.15s;
}

button.fareGroup__button_active-guTkJ,
button.fareGroup__button_active-guTkJ:hover,
button.fareGroup__button-382sp:hover {
	color: var(--white);

	background: var(--brand-1-0);
}

div.fareGroup_withoutUpgradeOption-1UNtX {
	min-height: auto;
}

@media screen and (max-width: 600px) {
	.buttons-2piNA {
		padding: 0 12px 0 20px;
	}

	button.fareGroup__button-382sp {
		min-width: 140px;
	}

	.buttons-2piNA button.fareGroup__button-382sp {
		margin-right: 8px;
		margin-bottom: 8px;
	}

	.compareFaresLink-3-QMK {
		width: 100%;
	}
}
