.root-2n0ad {
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;

	padding: 24px 0;

	color: var(--brand-1-0);

	background: var(--white);
}

.switch_wrapper-3r_iF {
	padding: 0 20px;

	text-align: left;
}

.switch_label-3igXQ {
	display: none;
}

.switch_control-3NF_p:after {
	background: var(--line-separator);
	opacity: 1;
}

.switch_wrapper-3r_iF .switch_control-3NF_p:before {
	left: 3px;
}

.switch_wrapper-3r_iF .switch_control-3NF_p:after {
	left: 0;
}

.switch_wrapper-3r_iF .switch_control-3NF_p:before,
.switch_wrapper-3r_iF .switch_control-3NF_p:after {
	right: auto;
}

.switch_wrapper-3r_iF .switch_active-VCJBR:before {
	left: 15px;

	background: var(--white);
}

.switch_active-VCJBR:after {
	background: var(--brand-1-0);
}

.price_wrapper-3p6IJ {
	align-items: center;
	justify-content: flex-start;

	margin-top: 0;
	margin-bottom: 16px;

	padding: 0 20px;
}

.price_label-zH2Po,
.price-hwMje {
	color: var(--brand-1-0);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

@media (max-width: 1024px) {
	.root-2n0ad {
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	.price_wrapper-3p6IJ {
		margin: 0;
		padding-top: 0;
	}

	.switch_control-3NF_p {
		margin-left: -30px;
	}
}
