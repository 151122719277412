.inner__inner-377JM {
	background: #f6f6f6;
}

.inner__inner-377JM,
.results-33xJJ {
	display: flex;
	flex-direction: column;

	padding-bottom: 20px;
}

.inner-1D6ql {
	flex: 1;
	display: flex;
	flex-direction: column;

	width: 100%;

	background: var(--bg-fill);
}

.header-IzJ_Q {
	flex-direction: column;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.05);
}

.header__stepbar-36rUq {
	border-color: var(--brand-1--1);
}

.toolbar_actions-2B-tl button.toolbar__backButton-1PmUY {
	position: relative;

	padding-right: 0;
	padding-left: 40px;

	color: var(--text-light);

	background: none;
}

.toolbar_actions-2B-tl button.toolbar__backButton-1PmUY:before {
	position: absolute;
	left: 0;

	width: 40px;
	height: 40px;

	border-radius: 50%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDExSDcuODNMMTMuNDIgNS40MUwxMiA0TDQgMTJMMTIgMjBMMTMuNDEgMTguNTlMNy44MyAxM0gyMFYxMVoiIGZpbGw9IiNCM0IzQjMiLz4KPC9zdmc+Cg==) no-repeat 50% 50%, var(--bg-fill);
	content: '';
}

.toolbar_actions-2B-tl button.toolbar__backButton-1PmUY:hover {
	color: var(--text-mid);

	background: none;
}

.toolbar_actions-2B-tl button.toolbar__backButton-1PmUY:hover:before {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMjAgMTFINy44M0wxMy40MiA1LjQxTDEyIDRMNCAxMkwxMiAyMEwxMy40MSAxOC41OUw3LjgzIDEzSDIwVjExWiIgZmlsbD0iIzgwODA4MCIvPgo8L3N2Zz4K);
}

@media screen and (max-width: 600px) {
	.toolbar_actions-2B-tl button.toolbar__backButton-1PmUY {
		color: transparent;
	}

	.toolbar_actions-2B-tl button.toolbar__backButton-1PmUY:before {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMjAgMTFINy44M0wxMy40MiA1LjQxTDEyIDRMNCAxMkwxMiAyMEwxMy40MSAxOC41OUw3LjgzIDEzSDIwVjExWiIgZmlsbD0iIzgwODA4MCIvPgo8L3N2Zz4K);
	}

	.results-33xJJ {
		background: var(--bg-fill);
	}
}
