.title-2x0Wy {
	display: inline-flex;
	align-items: center;

	padding: 0 32px 0 14px;
}

.title-2x0Wy:after {
	right: 14px;

	transform: translateY(-50%);
}

.currentSorting-3JGhL {
	color: var(--text-base);
}

.sortBy-OYDqU {
	margin-right: 6px;
}

button.flightType-Q-Bhl {
	padding: 0 14px;
}

button.flightType-Q-Bhl.flightType__active-1U0nd {
	padding-right: 8px;

	border-color: var(--brand-1-0);

	transition: background 0.15s, border 0.15s;
}

button.flightType-Q-Bhl.flightType__active-1U0nd:hover {
	border-color: var(--brand-1-1);
}

button.flightType-Q-Bhl.flightType__active-1U0nd:before {
	display: none;
}

button.flightType-Q-Bhl.flightType__active-1U0nd:after {
	content: '';

	width: 22px;
	height: 22px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjIxMjMgMTUuOTQ5N0wxMC41IDEyLjIzNzRMNi43ODc2NyAxNS45NDk3TDUuNTUwMjMgMTQuNzEyM0w5LjI2MjU0IDExTDUuNTUwMjMgNy4yODc2N0w2Ljc4NzY3IDYuMDUwMjNMMTAuNSA5Ljc2MjU0TDE0LjIxMjMgNi4wNTAyM0wxNS40NDk3IDcuMjg3NjdMMTEuNzM3NCAxMUwxNS40NDk3IDE0LjcxMjNMMTQuMjEyMyAxNS45NDk3WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==) no-repeat center / contain;
}

.title-2x0Wy,
button.flightType-Q-Bhl {
	min-height: 48px;

	border: 1px solid var(--white);
	border-radius: 40px;

	font-weight: 500;

	font-size: 16px;
	line-height: 48px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);

	background: var(--white);

	transition: border 0.15s;
}

.title-2x0Wy:hover,
button.flightType-Q-Bhl:hover {
	border-color: var(--brand-1-3);

	background: var(--white);
}

li.listItem-1c5L6 {
	padding: 12px 44px 12px 12px;

	border-radius: 4px;

	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
}

li.listItem-1c5L6:not(:last-of-type) {
	margin-bottom: 4px;
}

li.listItem-1c5L6:hover,
li.listItem-1c5L6.listItem_active-kHLVD {
	background: var(--brand-1-5);
}

li.listItem-1c5L6.listItem_active-kHLVD:after {
	right: 12px;

	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNTk5OTggMTQuNEw1LjM5OTk4IDEwLjJMMyAxMi44TDkuNTk5OTggMTkuNEwyMS42IDcuMzk5OTlMMTkuMiA1TDkuNTk5OTggMTQuNFoiIGZpbGw9IiM0NTM1RDQiLz4KPC9zdmc+Cg==) no-repeat center / contain;
}

div.paper-2glu1 {
	margin-top: 62px;

	border-radius: 12px;

	box-shadow: 4px 7px 26px 0 rgba(0, 0, 0, 0.1);
}

div.paper-2glu1 ul {
	padding: 12px;
}

@media screen and (max-width: 600px) {
	.title-2x0Wy {
		grid-gap: 6px;
		gap: 6px;

		padding: 0 14px;

		font-size: 14px;
	}

	.title-2x0Wy:before {
		position: relative;
		top: 0;

		transform: none;
	}

	.mobile__list-1ZqUS {
		padding: 0 12px;
	}

	.mobile__list__title-28B4Y {
		padding: 12px 8px;

		color: var(--text-base);
		font-weight: 700;

		font-size: 22px;
		line-height: 1.3;

		background: transparent;
	}

	button.mobile__list__close-33urT {
		right: 8px;

		width: 32px;
		height: 32px;

		border-radius: 50%;

		background-color: var(--bg-fill);
	}

	.mobile__list__item-D1plc:not(:first-of-type) {
		margin-top: 4px;

		border: none;
	}

	button.mobile__list__button-3jukd,
	button.mobile__list__button_inactive-1e2QC {
		padding: 12px;

		border-radius: 4px;

		font-weight: 500;
		font-size: 16px;
		line-height: 1.3;
	}

	button.mobile__list__button-3jukd:not(.mobile__list__button_inactive-1e2QC):before {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNTk5OTggMTQuNEw1LjM5OTk4IDEwLjJMMyAxMi44TDkuNTk5OTggMTkuNEwyMS42IDcuMzk5OTlMMTkuMiA1TDkuNTk5OTggMTQuNFoiIGZpbGw9IiM0NTM1RDQiLz4KPC9zdmc+Cg==) no-repeat center;
	}

	button.mobile__list__button-3jukd,
	button.mobile__list__button-3jukd:hover {
		color: var(--brand-1-0);

		background: var(--bg-fill);
	}

	button.mobile__list__button_inactive-1e2QC,
	button.mobile__list__button_inactive-1e2QC:hover {
		color: var(--text-mid);

		background: var(--white);
	}

	button.mobile__closeButton-1iUp- {
		min-width: 60px;
		min-height: 40px;

		padding: 0 12px;

		color: var(--text-mid);
		font-weight: 600;
		font-size: 16px;
		line-height: 40px;

		background: var(--bg-fill);
	}
}
