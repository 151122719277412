.wrapper-1eGTM,
.monthsList-1UVdP,
.monthsList_withPrice-3OlLc {
	width: 100%;
}

.monthsList-1UVdP,
.monthsList_withPrice-3OlLc {
	justify-content: space-evenly;
}

@media screen and (max-width: 600px) {
	.wrapper-1eGTM,
	.monthsList-1UVdP,
	.monthsList_withPrice-3OlLc {
		width: auto;
	}
}
