

button.button-AWZPc {
	width: 100%;
	height: 48px;
	margin-top: auto;
	padding: 0 12px;

	border: 1px solid var(--brand-1-0);
	border-radius: 4px;

	color: var(--brand-1-0);
	font-weight: 500;
	font-size: 18px;

	background: var(--white);
}

button.button-AWZPc:hover {
	color: var(--white);

	background: var(--brand-1-0);
}
