.amenities__item-Eama7 {
	display: inline-flex;
	width: 16px;
	height: 16px;
	align-items: center;
	justify-content: center;

	color: var(--brand-1-0);

	cursor: help;
}
