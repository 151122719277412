

.list__item-31kAj.list__item_selected-3r2lo {
	position: relative;

	padding: 8px 27px 8px 8px;

	color: var(--white);

	background: var(--brand-1-0);
}

.list__item-31kAj.list__item_selected-3r2lo:before,
.list__item-31kAj.list__item_selected-3r2lo:after {
	position: absolute;
	top: 50%;
	right: 11px;

	width: 12px;
	height: 1.5px;

	transform: translateY(-51%);
	background: var(--white);
	content: '';
}

.list__item-31kAj.list__item_selected-3r2lo:before {
	transform: rotate(45deg);
}

.list__item-31kAj.list__item_selected-3r2lo:after {
	transform: rotate(-45deg);
}

.list__item-31kAj.list__item_selected-3r2lo svg {
	display: none;
}
