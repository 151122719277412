.segments-3Ho24 {
	margin-right: 0;

	border-right: 1px solid var(--line-separator);
}

.segments_multiCity-rMqIH {
	border-right: none;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.segments-3Ho24:not(.segments_multiCity-rMqIH) {
		padding-bottom: 48px;
	}
}

@media (max-width: 1024px) {
	.segments-3Ho24 {
		border-right: none;
	}

	.segments_multiCity-rMqIH {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 600px) {
	.segments-3Ho24 {
		padding-bottom: 0;
	}

	.segments_multiCity-rMqIH {
		margin-bottom: 4px;
	}
}
