@media screen and (max-width: 600px) {
	.controls-3hILR {
		margin-bottom: 0;
		padding-bottom: 0;

		border-bottom: none;
	}

	.controls-3hILR > div {
		margin-bottom: 0;
	}
}
