

.popper-27Jo6 {
	top: -5px !important;
}

.container-10ZYV {
	background: var(--brand-1-0);
}

.tooltip-1frBr:after {
	border-top-color: var(--brand-1-0);
}

.tooltip-1frBr.tooltip_recommend-1nM5D:after,
.tooltip-1frBr.tooltip_withInfo-3ChJ1:after {
	border-top-color: var(--brand-1-1);
}
