.features-sS9O2 {
	display: flex;
	justify-content: space-between;
	align-self: center;
	margin: 0 20px 20px 20px;
	padding: 12px 16px;

	border-radius: 4px;

	color: var(--summary-flight-features-color);

	background-color: var(--brand-1-5);
}
