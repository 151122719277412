

.price-37z-y {
	border-color: var(--line-separator);
}

button.button-Gy5-B {
	background: var(--brand-3-0);
}

button.button-Gy5-B:hover {
	background: var(--brand-3--1);
}
