.passengers-21KOD {
	border-right: 1px solid var(--line-separator);
}

.passengers_isMultiCity-1bhwM {
	flex-basis: 50%;
	flex-grow: 1;
}

.menu-22D-R {
	right: 0;

	min-width: 280px;
}

.menu-22D-R ul li:not(:last-of-type) {
	border-color: var(--line-separator);
}

@media (max-width: 1024px) and (min-width: 601px) {
	.passengers_isMultiCity-1bhwM {
		flex-basis: 50.5%;
	}
}

@media screen and (max-width: 600px) {
	.passengers-21KOD {
		margin-top: 0;

		border-right: none;
	}

	.alternative-G_8zq .selector-Mlj0- {
		height: 48px;
	}

	.alternative-G_8zq .title-2Wi74 {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.3;
	}
}
