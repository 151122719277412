.wrapper-1EQ1J {
	padding: 32px;
}

.title-2iG4J {
	color: var(--text-base);
}

.footer-2z3MJ button {
	border-radius: 4px;
}

@media screen and (max-width: 600px) {
	.wrapper-1EQ1J {
		padding: 0;
	}

	.title-2iG4J {
		margin-bottom: 14px;
		padding-right: 0;
	}

	.footer-2z3MJ {
		margin-top: 32px;
	}

	.footer-2z3MJ button {
		flex: 1;
	}
}
