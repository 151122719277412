

.icon-v8v3F {
	margin-right: 12px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkluZm8gLyBJdGVtIEljbHVkZWQiPgo8cGF0aCBpZD0iU3VidHJhY3QiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjEgMTAuMzMzM0MyMSAxNi4xMzIzIDE2LjI5OSAyMC44MzMzIDEwLjUgMjAuODMzM0M0LjcwMTAxIDIwLjgzMzMgMCAxNi4xMzIzIDAgMTAuMzMzM0MwIDQuNTM0MzUgNC43MDEwMSAtMC4xNjY2NTYgMTAuNSAtMC4xNjY2NTZDMTYuMjk5IC0wLjE2NjY1NiAyMSA0LjUzNDM1IDIxIDEwLjMzMzNaTTE1LjQ5MzcgOC4zMjcwNkMxNS44MzU0IDcuOTg1MzUgMTUuODM1NCA3LjQzMTMzIDE1LjQ5MzcgNy4wODk2MkMxNS4xNTIgNi43NDc5MiAxNC41OTggNi43NDc5MiAxNC4yNTYzIDcuMDg5NjJMOS4zNDg2OCAxMS45OTcyTDYuNzQzNzIgOS4zOTIyNkM2LjQwMjAxIDkuMDUwNTUgNS44NDc5OSA5LjA1MDU1IDUuNTA2MjggOS4zOTIyNkM1LjE2NDU3IDkuNzMzOTcgNS4xNjQ1NyAxMC4yODggNS41MDYyOCAxMC42Mjk3TDkuMzQ4NjggMTQuNDcyMUwxNS40OTM3IDguMzI3MDZaIiBmaWxsPSIjNUZDQTQzIi8+CjwvZz4KPC9zdmc+Cg==) no-repeat center;
}

.icon-v8v3F svg {
	display: none;
}

.root-12ySP.type_account-34Wr8 {
	font-weight: 700;
}

div.root-12ySP.type_review-3jkRu span.security_code-2wKOp {
	display: none;
}
