@media screen and (max-width: 600px) {
	.controls__wrapper-1iyMz,
	.leg-2dseS {
		max-width: 100%;
	}

	.controls__wrapper-1iyMz {
		align-items: center;
	}

	.leg-2dseS {
		border-color: var(--line-separator);

		color: var(--text-base);
		font-size: 16px;
		font-weight: 500;
	}
}
