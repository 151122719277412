

.price-1b0M- {
	font-size: 14px;
}

.price__wrapper-2-gFi {
	font-weight: 700;
	color: var(--text-base);
}

.image-1twVk {
	display: none;
}

.seat_free-1gc82 .title-vQX0q:before {
	transform: none;
}

.seat_business-3m_UT .title-vQX0q:after {
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyNCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkV4dHJhIj4KPHBhdGggaWQ9IkV4dHJhIGxlZyByb29tIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDNMMTggOEgxNFYxMUgxMFY4SDZMMTIgM1oiIGZpbGw9IiM0RDRENEQiLz4KPC9nPgo8L3N2Zz4K);

	top: 1px;
	left: 10px;
	position: absolute;
}

div.additionalInfo-3wcFt {
	background: var(--brand-1-1);
}

.note-1YhZ5 div.additionalInfo-3wcFt {
	background: none;
}
