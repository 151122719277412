.selectedFare-2aDz_ {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;

	border: 1px solid var(--brand-1-0);

	background: var(--brand-1-2);
	opacity: 0.3;

	transition: height 100ms;
}

div.compareFares-1lTyF {
	border-radius: 20px;
}

@media screen and (max-width: 600px) {
	.slider_withoutImages-X9350 .slick-prev,
	.slider_withoutImages-X9350 .slick-next {
		top: 32px;
	}

	.slider_withoutImages-X9350 .slick-prev,
	.slider_withoutImages-X9350 .slick-next,
	.slider_withoutImages-X9350 .slick-prev:hover,
	.slider_withoutImages-X9350 .slick-next:hover {
		border: none;

		background: var(--white);
	}

	.slider_withoutImages-X9350 .slick-prev svg *,
	.slider_withoutImages-X9350 .slick-next svg * {
		fill: var(--text-base);
	}
}
