

.fareGroup_white-OTgji .info-1S-CD {
	color: var(--brand-1-0);
}

.fareGroup_white-OTgji .keyFeature-2oVPM {
	background-color: var(--brand-1-0);
}

.fareGroup_white-OTgji.fareGroup_Business-1RIba .keyFeature-2oVPM {
	background: var(--brand-1-1);
}

.fareGroup-3bl-l .chevron {
	position: relative;
	top: 8px;
	left: -4px;

	color: var(--brand-1-0);

	transform: scale(0.9);
}

.fareGroup_white-OTgji .option__title-2FVfm {
	font-size: 14px;
	color: var(--brand-1-0);
}

.fareGroup_white-OTgji .option__icon-2UmJ7,
.fareGroup_white-OTgji .option__value-2Eugw {
	color: var(--brand-1-0);
}

.upgradeOffer__link-3FWoY {
	display: block;
	margin-top: 5px;

	color: var(--brand-1-0);
	font-weight: 600;
	line-height: 14px;
	text-decoration: none;
}

.fareGroup-3bl-l {
	padding: 20px;
}

div.fareGroup_recommended-1wHHi:not(.selectedFare-38L27) {
	box-shadow: 0 0 0 2px var(--fareGroup-recommended-border-color);
}

span.fareOption__title-1J29O {
	white-space: pre-wrap;
}

.fareGroup_white-OTgji .option-3R9Pv,
.fareGroup_white-OTgji span.fareOption__title-1J29O {
	color: var(--text-mid);
}

.fareGroup_white-OTgji .option__value-2Eugw {
	color: var(--text-pale);
}

.fareGroup_white-OTgji .option__value-2Eugw .icon_included {
	color: var(--success-icon);
}

.option-3R9Pv.option_unavailable-31Kvx {
	display: none;
}

button.button-2Uaea {
	margin-top: 12px;
}
