

div.transfer-3nxqq {
	border-color: var(--line-separator);

	background-color: var(--warning-bg);
}

.transfer__layover-3_KQ0 {
	color: var(--text-base);
}
