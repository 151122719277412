

.seat-14BTF.seat_occupied-1qs_Z:hover {
	background: var(--brand-1-0);
}

.seat-14BTF.seat_occupied-1qs_Z:not(.seat_confirmed-2-bl2):hover:after {
	top: 0;
	left: -1px;

	width: 24px;
	height: 24px;

	transform: scale(1.4);
	filter: brightness(0.1);
}

.seat-14BTF.seat_business-YjYPe:not(.seat_occupied-1qs_Z):not(.seat_unavailable-1VV0j):before {
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyNCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkV4dHJhIj4KPHBhdGggaWQ9IkV4dHJhIGxlZyByb29tIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDNMMTggOEgxNFYxMUgxMFY4SDZMMTIgM1oiIGZpbGw9IiM0RDRENEQiLz4KPC9nPgo8L3N2Zz4K);

	position: absolute;
	top: 0;
	left: 10px;
}

.seat-14BTF.seat_business-YjYPe:not(.seat_occupied-1qs_Z):not(.seat_unavailable-1VV0j):hover:before {
	content: '';
}

.single-Wd761.single_cancel-eHdSC {
	background: var(--brand-3-0);
}
