

.timeInRoute-7jttr {
	top: 30px;

	padding: 0 6px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
}

.flightNumber-4JDCW {
	color: var(--brand-1-0);
}
