.dates-39cr7 {
	border-radius: 0;

	color: var(--text-base);
}

.dates__to-3fZ-P,
.dates__back-3CAgC {
	position: relative;
}

.dates_focused-3Uufl .dates__to-3fZ-P:not(.dates__to_focused-3Zy0J):after {
	border: none;
}

.dates__back-3CAgC:before {
	border-left: 1px solid var(--line-separator);

	opacity: 1;
}

.dates_focused-3Uufl .dates__back-3CAgC:before {
	border-color: var(--line-separator);
}

.dates__to-3fZ-P:before {
	left: 12px;
}

.dates__to-3fZ-P:before,
.dates_focused-3Uufl .dates__to-3fZ-P:before {
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOCA0SDE5QzIwLjEgNCAyMSA0LjkgMjEgNlYyMEMyMSAyMS4xIDIwLjEgMjIgMTkgMjJINUMzLjg5IDIyIDMgMjEuMSAzIDIwTDMuMDEgNkMzLjAxIDQuOSAzLjg5IDQgNSA0SDZWMkg4VjRIMTZWMkgxOFY0Wk01IDIwSDE5VjlINVYyMFoiIGZpbGw9IiNCM0IzQjMiLz4KPC9zdmc+Cg==) 50% 50% / contain;
}

.dates-39cr7,
.dates-39cr7:not(.dates_focused-3Uufl):hover {
	background: var(--white);
}

.placeholder-cZzDV {
	color: var(--text-base);
}

.date__inputWrapper-2oo-K {
	color: var(--text-base);
}

.dow-2eODU {
	color: var(--text-light);

	opacity: 1;
}

.dow-2eODU:before {
	color: var(--text-base);
}

.dates__to_focused-3Zy0J .dow-2eODU,
.dates__back_focused-1BT_R .dow-2eODU {
	color: var(--text-light);
}

.highlighted_minPrice-3e56S {
	background: var(--brand-1-5);
}

@media desktop_only {
	.dates_focused-3Uufl .dates__to-3fZ-P.dates__to_focused-3Zy0J:after,
	.dates_focused-3Uufl .dates__back-3CAgC.dates__back_focused-1BT_R:after,
	.dates_focused-3Uufl .dates__to-3fZ-P:hover:after,
	.dates_focused-3Uufl .dates__back-3CAgC:hover:after {
		position: absolute;
		top: auto;
		right: auto;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 3px;
		margin: 0;

		background: var(--brand-3-0);

		pointer-events: none;
		content: '';
	}
}

@media (max-width: 1024px) {
	.dates-39cr7 {
		border-radius: 0;
	}
}

@media screen and (max-width: 600px) {
	.alternative-2IjsN.dates-39cr7 {
		border-radius: 0;
	}

	.alternative-2IjsN .dates__to-3fZ-P {
		font-weight: 500;
	}

	.alternative-2IjsN .dow-2eODU {
		display: inline-block;

		color: var(--text-base);
	}

	.dates__to_text-_ZpMr {
		color: var(--text-base);
		font-size: 16px;
		font-weight: 500;
		line-height: 1.3;
	}

	.alternative_dow-1Jr1G {
		display: none;
	}
}
