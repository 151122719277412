

.root-16EmV .slick-arrow {
	z-index: 2;

	display: flex !important;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 34px;

	border-radius: 50%;

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
	background: #ffffff;
}

button.add-2cwld {
	width: 32px;
	min-width: 32px;
	height: 32px;

	padding: 6px;

	border-radius: 50%;

	color: var(--white);

	background-color: var(--brand-1-0);
}

button.add-2cwld:hover {
	background-color: var(--brand-1-1);
}

.baggageDirection__root-2t8WT {
	color: var(--success-text);
	font-weight: 700;
}

.baggageCount__root-3GUvr {
	font-weight: 500;
}
