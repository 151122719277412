

.dayOfWeekName_withPrices-sreYC {
	width: 43px;
	height: 36px;
}

.dayOfWeekName_withPrices-sreYC span {
	color: var(--text-pale);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.dayOfWeekName_withPrices-sreYC:nth-child(6n) span,
.dayOfWeekName_withPrices-sreYC:nth-child(7n) span {
	font-weight: 500;
}
