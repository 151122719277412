

.item-us3Me {
	border: 1px solid transparent;
}

.item-us3Me.item_active-2Zxak {
	border: 1px solid var(--brand-1-2);

	color: var(--text-base);

	background: var(--white);
}

.item-us3Me.item_recommend-2xowH:not(.item_active-2Zxak) {
	color: var(--white);

	background: var(--brand-1--1);
}

.clear-R34wU svg {
	color: var(--error-icon);
}

.item-us3Me .money-1AxmU,
.item-us3Me.item_active-2Zxak .money-1AxmU,
.item-us3Me.item_recommend-2xowH .money-1AxmU,
.item-us3Me.item_recommend-2xowH .select-25k2L,
.item-us3Me.item_recommend-2xowH svg,
.item-us3Me.item_active-2Zxak .select-25k2L {
	color: var(--brand-1-0);
}

.item-us3Me.item_active-2Zxak .clear-R34wU svg {
	color: var(--error-icon);
}

.item-us3Me.item_active-2Zxak .avatar-3kVXE {
	color: var(--white);

	background: var(--brand-1--1);
}
