

.header__cell-f5GwO:nth-child(3) {
	flex-basis: 301px;

	color: var(--white);

	background: var(--brand-1-0);
}

.details-3SqaI {
	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}
