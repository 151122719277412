.caption-1ahBc {
	color: var(--brand-1-3);
	font-weight: 500;

	opacity: 1;
}

@media screen and (max-width: 600px) {
	.multiCity-1eOYi {
		margin-bottom: 0;
		padding-bottom: 0;

		border-bottom: none;
	}
}
