

.subsidies-KXjAQ {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 17px;
	margin-bottom: 8px;

	color: var(--brand-1-0);
	font-size: 14px;
}

.selected-3Gpo2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 12px;

	color: var(--brand-1-0);
	font-size: 14px;
}

.price__close-1KadE {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	margin-left: 8px;

	border-radius: 50%;

	color: var(--brand-1-1);

	background: var(--brand-1-5);
}

button.price-319YQ {
	display: flex;
	justify-content: center;
	align-items: center;

	min-width: 150px;
	min-height: 44px;

	padding: 0 12px;

	border-radius: 100px;

	color: #ffffff;
	font-size: 14px;
	line-height: 18px;

	cursor: pointer;

	background: var(--brand-3-0);
}

.selectedFare-2TVLp {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 13px 12px;

	font-size: 14px;
	font-weight: 500;
	color: var(--brand-1-0);
}
