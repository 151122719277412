

.baggageBanner-Yq72K {
	height: 70px;

	background: var(--bg-white);
}

.image-1QRxB {
	display: none;
}

.disclaimer-oUPNP {
	margin-top: 0;
}

.disclaimer-oUPNP a {
	color: var(--brand-1-0);
	font-weight: 400;
}

.disclaimer__icon-1Hrhy {
	color: var(--text-pale);
}

.text-NgfmA {
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	max-width: unset;

	color: var(--text-base);
	font-weight: 700;
	font-size: 24px;
}
