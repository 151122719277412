.wrapper-38JgP {
	border-right: none;
}

.cell-1PZIX .input-nw_bX {
	border-radius: 0;
}

.input-nw_bX:not(:focus):not(.input_focused-R03m0):hover {
	background: var(--white);
}

.input-nw_bX:-ms-input-placeholder {
	color: var(--text-base);
}

.input-nw_bX::placeholder {
	color: var(--text-base);
}

.remover-2H5k5 {
	top: 50%;
	right: -8px;

	width: 36px;
	min-width: 36px;
	height: 36px;

	border-radius: 50%;

	transform: translate(100%, -50%);

	opacity: 1;

	background: var(--brand-1-1);

	transition: background 0.15s;
}

.remover-2H5k5:hover {
	background: var(--brand-1-2);
}

.segment-1pomf:first-child .remover-2H5k5 {
	display: none;
}

.segments_multiCity-35muK .segment-1pomf {
	position: relative;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.cell-1PZIX:nth-child(2) {
		border-right: none;
	}

	.cell-1PZIX:not(:last-of-type) {
		padding: 0;
	}

	.segments_multiCity-35muK .segment-1pomf {
		padding-right: 44px;
	}

	.remover-2H5k5 {
		right: 0;

		transform: translateY(-50%);
	}

	.withMultiCity-1rrOr {
		flex-wrap: nowrap;
	}

	.withMultiCity-1rrOr .cell_date-144rD {
		flex-basis: 50%;

		border-right: none;
	}

	.withMultiCity-1rrOr .datepicker_value-mN_4P {
		border-radius: 0 4px 4px 0;
	}
}

@media (max-width: 1024px) {
	.cell_date-144rD {
		margin: 0;
		padding: 0;

		border-right: 1px solid var(--line-separator);
	}

	.cell-1PZIX:first-of-type .input-nw_bX {
		border-bottom: 1px solid var(--line-separator);
		border-radius: 4px 0 0 0;
	}

	.cell-1PZIX:nth-of-type(2) .input-nw_bX {
		border-bottom: 1px solid var(--line-separator);
		border-radius: 0 4px 0 0;
	}

	.segments_multiCity-35muK .cell-1PZIX:first-of-type .input-nw_bX {
		border-radius: 4px 0 0 4px;
	}

	.segments_multiCity-35muK .cell-1PZIX:nth-of-type(2) .input-nw_bX {
		border-right: 1px solid var(--line-separator);
		border-radius: 0;
	}

	.segments_multiCity-35muK .cell-1PZIX:first-of-type .input-nw_bX,
	.segments_multiCity-35muK .cell-1PZIX:nth-of-type(2) .input-nw_bX {
		border-bottom: none;
	}
}

@media screen and (max-width: 600px) {
	.input_text-iDc9n {
		font-size: 16px;
		font-weight: 500;
	}

	.cell-1PZIX:first-of-type .input-nw_bX,
	.cell-1PZIX:nth-of-type(2) .input-nw_bX {
		border: none;
	}

	.cell_date-144rD,
	.alternative-2FNzB div.cell_location-1cHks:nth-of-type(2) {
		border-right: none;
	}

	.alternative-2FNzB .withMultiCity-1rrOr .cell_location-1cHks:first-of-type,
	.alternative-2FNzB .withMultiCity-1rrOr .cell_location-1cHks:nth-of-type(2) {
		padding-right: 2px;
	}

	.alternative-2FNzB .cell_date-144rD {
		height: 48px;

		margin-bottom: 4px;
	}

	.segments_multiCity-35muK div.segment-1pomf .cell_location-1cHks:first-of-type .input-nw_bX {
		border-radius: 0;
	}

	.segments_multiCity-35muK div.segment-1pomf:first-of-type .cell_location-1cHks:first-of-type .input-nw_bX {
		border-radius: 4px 0 0 0;
	}

	.segments_multiCity-35muK div.segment-1pomf:first-of-type .cell_date-144rD .datepicker_value-mN_4P {
		border-radius: 0 4px 0 0;
	}
}
