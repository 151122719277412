

button.button-2VIC8 {
	min-width: 100px;
	height: 40px;
	padding: 0 20px;

	border: none;
	border-radius: var(--button-border-radius);

	color: #ffffff;
	font-weight: 500;

	font-size: 15px;

	line-height: 40px;
	white-space: nowrap;

	cursor: pointer;
	background-color: var(--brand-1-0);

	transition: background-color 0.15s, opacity 0.15s;

	appearance: none;
}

button.button-2VIC8:hover {
	background-color: var(--brand-1-1);
}

button.button_share-3C0W1 {
	height: auto;
	padding: 12px 44px 12px 66px;

	border-radius: 4px;

	line-height: 18px;
	white-space: normal;

	background: var(--brand-1-0);
}

button.button_outline-WmXKO {
	border: 1px solid var(--brand-1-3);

	color: var(--brand-1-0);
	line-height: 38px;

	background-color: var(--brand-1-5);
}

button.button_outline-WmXKO:hover {
	background-color: var(--brand-1-1);
}

button.button_outline-WmXKO:disabled {
	opacity: 0.5;

	pointer-events: none;
}

button.button_flat-QLdVT {
	border-radius: var(--button-flat-border-radius);
}

button.button_text-29kpB {
	min-width: 0;
	height: auto;
	padding: 4px 8px;

	border-radius: 4px;

	color: var(--brand-1-0);
	line-height: normal;

	background-color: transparent;
}

button.button_text-29kpB:hover {
	background-color: transparent;
}

button.button_small-1ryfP {
	height: 36px;

	line-height: 36px;

	font-size: 14px;
	font-weight: 500;
}

button.button_large-1rEwQ {
	height: 48px;
	padding: 0 35px;

	line-height: 48px;
}

button.button_outline-WmXKO.button_large-1rEwQ {
	line-height: 46px;
}

button.button_outline-WmXKO.button_small-1ryfP {
	line-height: 34px;
}

.loader-QasOT,
.success-2qfOP {
	width: 40px;
	height: 40px;

	border-radius: 50%;

	transition: background-color 0.2s;
}

.loader-QasOT {
	background-color: var(--brand-1-0);
}

.loader_outline-3vnYc {
	background-color: transparent;
}

.success-2qfOP {
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #2bb46a;
}

.loader__spinner-16G9E {
	width: 40px;
	height: 40px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKKSURBVHgBtZZLaxNRGIa/ialak9pag9p6KwUrgqI7K7pQoeBC0IULXQguBUE3/gD/gVtdCC5diRdciAtRXChSWy/tLohSJQqi0dJom2R83pwTiZGYTDvzwsMJme9877nNnC+wDhSG4SaaERiCflgFy2AePkMBpoMgyLfLFbQx2kEzCn2QhpQ3Svu2+fc3uI/xs0iGGGVpjsB6b1KGlzADRRIWfdxKmkHYCbsh5wfwFS4T96WtIUm2+Vl1wxw8pOMH60D03UtzFNbBT7hO34mWhnTYTHPY3PJMwwQdflkEkUMDPQZj5lbnGjme1J+nGgIzNPugCi8IehrVrDaDICjBDX7e9flPkzv3jyE6ZH5mdJi0JYoct2geQA9c/MuQEWyl0QH4Aa8sPt0EHZxhPA7+MUTbzS3la0a2YDGJXDp0V8yt3KmaIc5rzc+OgHcWs8g5Ze7EbsBrl2aod60CHR39ReqeuVmOyjDjDQuWnN6Y2749jYbfLTl9gi4YkKFe/jJrXbaERG4ZyqtX3z2dztCSl7xqrjpBFU7QCktI/jLQoSnJsGTuNlhuyWnEG+ZlqKtEy9pjyUmfzXSjoU5pzpJT/QZ6lOIEzZq790J/Y8Qqch6nUYlSwGu8/i3VS69lHSAgZTGJXNqmc+Zmd1X/1ZLjrFpkzj/ot/h0HraYm93t5tF0wbCnz5YoclyAPIzDxlZBWRhSqQGrIYjoY77fJXgPb2GsXYduGATtZy7KB4HY/fAcPsIUnGiOaVUmai/X+Od6ZXQpq+hdYC+qDXGqFLRHB+AsZH28qoYzxM5YFGl20OuXKaM61O91AFWYhxLMQhEm4eT/cna0R37GIvRUPKpXVN4/hjuN5WAr/QakWztb10DkIQAAAABJRU5ErkJggg==) center no-repeat;

	animation: rotation-387FK 0.9s infinite linear;

	pointer-events: none;
}

@keyframes rotation-387FK {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
