

.wrapper-34Gbt {
	background: var(--white);
	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
}

.wrapper-34Gbt.wrapper_business-V0OW7 {
	background: var(--white);
}

.header-2PFQ2 {
	flex-direction: row-reverse;
	padding: 20px 20px 8px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.city__weather-30gKY {
	display: none;
}

.flightInfo__item-2miEb:first-child {
	order: 1;
	margin-left: 40px;
}

.flightInfo__item-2miEb:last-child {
	margin-left: 0;
}

.flightInfo__title-2AcPe,
.city__airport-1vMPN {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.flightInfo__value-25hRD,
.city__info--kpmK {
	color: var(--text-base);
	font-size: 42px;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 1.3;
}

.city__iata-nIfGw,
.flightInfo__item-2miEb:last-child .flightInfo__value-25hRD {
	font-weight: 700;
}

.city__time-1Ya0C,
.flightInfo__item-2miEb:first-child .flightInfo__value-25hRD {
	font-weight: 300;
}

.plane__icon-56ze_ {
	width: 30px;
	height: 30px;
	margin: 0 26px;

	color: var(--text-pale);

	transform: rotate(180deg) translateY(-10px);
}

.plane__icon-56ze_ > svg {
	width: 100%;
	height: 100%;
}

.airline__name-1Ddp0 {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.footer-1fWE9 > div:last-child {
	display: flex;
}

.footer__item-1H-zb {
	display: flex;
	flex-direction: column;

	color: transparent;
	line-height: 0;
}

.footer__item-1H-zb:last-child {
	align-items: flex-end;
}

.footer__item-1H-zb > span:first-child {
	color: var(--text-light);

	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;

	text-transform: uppercase;
}

.footer__item-1H-zb > span:last-child {
	color: var(--text-base);

	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

.footer__item-1H-zb:not(:last-child) {
	margin-right: 28px;
	padding-right: 0;

	border-right: none;
}

.flightInfo__title-2AcPe {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.flightInfo__title-2AcPe:after {
	display: none;
}

.footer__item-1H-zb:last-child .flightInfo__title-2AcPe {
	text-align: right;
}

.footer__airline-3MUD- {
	height: 17px;
}
