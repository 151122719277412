.startSearch-Ufhdb {
	flex-basis: 64px;
}

.startSearch_multiCity-3BNcE {
	flex-basis: 230px;
	margin-left: 4px;
}

.startSearch_multiCity-3BNcE button {
	border-radius: 4px;
}

.startSearch-Ufhdb button,
.startSearch-Ufhdb.startSearch_iconMode-25Fjg button {
	min-width: auto;
	padding: 0 12px;

	border-radius: 0 4px 4px 0;

	background: var(--start-search-button);
}

.startSearch-Ufhdb button:hover,
.startSearch-Ufhdb.startSearch_iconMode-25Fjg button:hover {
	background: var(--brand-3--1);
}

@media (max-width: 1024px) and (min-width: 601px) {
	.startSearch-Ufhdb {
		flex: 1 0 auto;
	}

	.startSearch-Ufhdb:not(.startSearch_multiCity-3BNcE) {
		position: absolute;
		top: 100%;

		flex-basis: 100%;
		width: 200%;

		transform: translateX(-50%);
	}

	.startSearch-Ufhdb button,
	.startSearch-Ufhdb.startSearch_iconMode-25Fjg button {
		border-radius: 0 0 4px 4px;
	}

	.startSearch-Ufhdb.startSearch_multiCity-3BNcE {
		flex-basis: 33.3%;
	}

	.startSearch-Ufhdb.startSearch_multiCity-3BNcE button {
		border-radius: 4px;
	}
}

@media screen and (max-width: 600px) {
	.startSearch-Ufhdb {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		width: 100%;

		margin: 4px 0 0 0;

		transform: none;
	}

	.startSearch-Ufhdb.startSearch_iconMode-25Fjg {
		flex-basis: 48px;
	}

	.startSearch-Ufhdb button {
		border-radius: 0 0 4px 4px;
	}

	.startSearch_multiCity-3BNcE {
		flex-basis: auto;
	}
}
