

button.button-2OAVn {
	color: var(--brand-1-0);

	background-color: var(--brand-1-5);
}

button.button-2OAVn:not(.buttonDisabled-3wabw):hover {
	color: var(--bg-white);

	background-color: var(--brand-1-0);
}
