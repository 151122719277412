.wrapper-3q6fP {
	padding: 20px 16px 20px 76px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: var(--white);
}

.wrapper-3q6fP.statusMessage_success-2Iy-g:before {
	left: 16px;

	width: 44px;
	height: 44px;

	background: var(--seat-hover-choosed) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMDAwMzkgMTEuMkwxLjgwMDM5IDYuOTk5OThMMC40MDAzOTEgOC4zOTk5OEw2LjAwMDM5IDE0TDE4LjAwMDQgMS45OTk5OEwxNi42MDA0IDAuNTk5OTc2TDYuMDAwMzkgMTEuMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.statusMessage__title-3vvwD {
	margin-bottom: 3px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 24px;
	line-height: 1.3;
}

.statusMessage__text-3EHc4 {
	color: var(--text-mid);
	font-size: 18px;
	font-weight: 400;
	line-height: 1.3;
}

@media screen and (max-width: 600px) {
	.wrapper-3q6fP {
		padding: 20px;
		margin: 0 0 12px 0;

		border-radius: 16px;
	}

	.wrapper-3q6fP.statusMessage_success-2Iy-g:before {
		top: 20px;
		left: 20px;
	}

	.statusMessage__title-3vvwD {
		margin-bottom: 8px;

		font-size: 20px;
	}

	.statusMessage__text-3EHc4 {
		font-size: 16px;
	}
}
