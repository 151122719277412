

.header-1ZWTD {
	color: var(--text-base);
}

.legNumber-2m6zW {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;

	font-size: 16px;
	font-weight: 400;
	line-height: 36px;
}

.title__text-HGZT4,
.title__cities-ffMPP {
	font-weight: 700;
	line-height: 1.3;
}

.title__cities-ffMPP {
	display: inline;

	color: var(--brand-1--2);
}

.title__cities-ffMPP svg {
	display: none;
}

.title__cities-ffMPP > span:first-of-type:after {
	content: ' \2014   ';
}

button.diagram-JzkLq {
	font-size: 16px;
	font-weight: 500;
}

button.diagram-JzkLq svg {
	opacity: 1;
}
