

.header-6flEB {
	display: block;

	margin-bottom: 12px;

	font-weight: 700;

	font-size: 22px;
	line-height: 1.3;
}

div.container-14lVr {
	display: grid;
	grid-template-columns: 1fr 1fr;

	margin-top: 0;
}

div.container-14lVr div.option-1NPvn {
	position: relative;

	min-height: 94px;

	border: 1px solid var(--brand-1-5);
	border-radius: 2px;

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

div.container-14lVr div.option-1NPvn.paid-3WQxg,
div.container-14lVr div.option-1NPvn.notAvailable-2M5Gg {
	border-color: var(--line-separator);

	background: none;
}

.notAvailable-2M5Gg .icon-3VrBf,
div.container-14lVr .paid-3WQxg .icon-3VrBf {
	color: var(--text-pale);
}

div.container-14lVr div.option-1NPvn .icon-3VrBf {
	width: 48px;
	height: 48px;

	color: var(--brand-1-0);
}

div.container-14lVr div.option-1NPvn.paid-3WQxg .title-336sc,
div.container-14lVr div.option-1NPvn.notAvailable-2M5Gg .title-336sc {
	display: block;

	color: var(--text-pale);
}

div.container-14lVr div.option-1NPvn .availabilityIcon-tOYWi {
	position: absolute;
	top: 8px;
	right: 8px;

	display: block !important;
}

.upgradeOffer-2qp03 {
	margin-top: 12px;
	margin-bottom: 0;
}
