

.dialog-3AdVj {
	overflow: hidden;
}

div.paper-316MD,
.dialog-3AdVj {
	border-radius: 12px;
}

.cities-2sIGx {
	border-radius: 0 12px 12px 0;
}

.label-cex96 {
	margin-bottom: 28px;

	color: var(--brand-1--2);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.countries-1BuHh {
	display: flex;
	flex-direction: column;

	background: var(--bg-fill);
}

.countries-1BuHh,
.cities-2sIGx {
	padding: 28px;
}

.countries__list-2AqlG {
	display: block;
	flex: 1;
	padding-bottom: 0;

	column-count: 3;
}

.country-2NKat {
	display: flex;
	width: 94%;

	max-width: 100%;

	margin-bottom: 4px;
	margin-right: 16px;
	padding: 8px 12px;

	border-radius: 4px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	text-transform: inherit;
}

.country_active-MBmFU {
	color: var(--white);

	background: var(--brand-1-0);
}

.city-13-7N {
	width: 100%;
	max-width: 100%;

	padding: 12px 16px;

	border-bottom: none;

	border-radius: 4px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
}

.city-13-7N:hover {
	background: var(--brand-1-5);
}

.iata-3kc1u {
	color: var(--text-pale);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}
