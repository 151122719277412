.currency-3QxRi {
	margin-right: 0;

	border-right: 1px solid var(--line-separator);

	white-space: nowrap;
}

.controls_multiCity-1tWc6 .currency-3QxRi {
	flex-basis: 50%;

	border-right: none;
}

button.selector-1ScYO {
	border-radius: 0;

	color: var(--text-base);

	background: var(--white);
}

.controls_multiCity-1tWc6 button.selector-1ScYO {
	border-radius: 0 4px 4px 0;
}

.currency-3QxRi button.selector-1ScYO:focus,
.currency-3QxRi button.selector-1ScYO:not(:focus):hover {
	background: var(--white);
}

.arrowIcon-1ojKG {
	color: var(--text-pale);
}

.item-U8TyB:hover {
	background: var(--bg-fill);
}

.dropdown-3Jb6R {
	border-radius: 2px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 600px) {
	.currency-3QxRi {
		margin-top: 4px;

		border-right: none;
	}

	.dropdown-3Jb6R {
		border-radius: unset;

		box-shadow: none;
	}
}
