

.airport-1l7u6 {
	flex: 1;

	color: var(--brand-1-0);
}

.time-1LHzV,
.city-1GLh- {
	color: var(--text-base);
}

.date-1HLpE {
	color: var(--text-light);
}

.isMobile-5vr21 .date_icon-1w4S3 {
	margin-right: 8px;

	color: var(--text-pale);
}

.isMobile-5vr21 .time_iata-1E9Go {
	color: var(--text-light);
}
