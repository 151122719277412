

.service-Y60eP {
	background: var(--brand-1-5);
}

.service__icon-3r6Kz {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--brand-1-0);
}

.service__info-1_-nv {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.service__name-5QANI {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}
