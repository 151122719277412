.actions-298wm {
	top: 6px;
	left: 100%;

	margin-top: 0;

	transform: translateX(8px);
}

button.addSegment-3R4GQ {
	width: 36px;
	min-width: 36px;
	height: 36px;

	border-radius: 50%;

	background: var(--brand-1-1);
}

button.addSegment-3R4GQ:hover {
	background: var(--brand-1-2);
}

@media (max-width: 1024px) and (min-width: 601px) {
	.actions-298wm {
		left: auto;
		right: 0;

		transform: none;
	}
}

@media screen and (max-width: 600px) {
	.actions-298wm {
		top: 0;
		left: 0;

		transform: none;
	}

	button.addSegment-3R4GQ {
		flex-grow: 1;
		width: 100%;
		height: var(--input-height);

		border-radius: 0;
	}

	button.addSegment-3R4GQ,
	button.removeSegment-1eO81,
	button.addSegment-3R4GQ:not(:disabled):hover,
	button.removeSegment-1eO81:not(:disabled):hover {
		color: var(--text-pale);

		background: var(--white);
	}
}
