

.flightInfo__date-fp5kN {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.flightInfo__airline-2DJgR,
.aircraft-22XOn {
	color: var(--text-light);
	font-size: 13px;
	line-height: 1.3;
}

.segment-XlJyz.segment_additional-1zLRl,
.totalDuration__decorator-Lc-JS {
	border-color: var(--line-separator);
}

.flightInfo__nextDay-KlIrp .flightInfo__date-fp5kN {
	color: var(--text-base);
}

.planeInfo-3oKGN {
	justify-content: center;
}
