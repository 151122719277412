

.arrow-ncOVP:after {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjM4IiBoZWlnaHQ9IjM4IiByeD0iMTkiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xNyAxM0wxNS41OSAxNC40MUwyMC4xNyAxOUwxNS41OSAyMy41OUwxNyAyNUwyMyAxOUwxNyAxM1oiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) no-repeat center;
}

.arrow_prev-2pbuy:after {
	transform: rotate(-180deg);
}

.arrow_prev-2pbuy:after,
.arrow_next-2elLu:after {
	margin-top: 0;
}

.arrow_prev-2pbuy:before,
.arrow_next-2elLu:before {
	position: absolute;
	top: 50%;
	left: 50%;

	z-index: -1;

	width: 76%;
	height: 76%;

	border-radius: 50%;

	box-shadow: 4px 7px 26px 0 rgba(0, 0, 0, 0.1);

	transform: translate(-50%, -50%);
	content: '';
}
