.time-3AMbz {
	color: var(--text-base);
	font-size: 42px;
	font-weight: 700;
	line-height: 1.3;
}

.airport-1R4Jq {
	font-weight: 400;
	line-height: 1.3;
}

.point_thin-hfKRS .time-3AMbz {
	color: var(--text-pale);
}

@media screen and (max-width: 600px) {
	.date__container-1M8Bs {
		align-items: center;
	}
}
