:root {
	--brand-1-0: #4535d4;
	--brand-1--2: #34289f;
	--brand-1--1: #3b2db4;
	--brand-1-1: #6153da;
	--brand-1-2: #8f86e5;
	--brand-1-3: #b5aeee;
	--brand-1-4: #dad7f6;
	--brand-1-5: #eceafb;
	--brand-2-0: #3562d5;
	--brand-2-1: #5379db;
	--brand-2-2: #86a1e6;
	--brand-2-3: #aec0ee;
	--brand-2-4: #d7e0f7;
	--brand-2-5: #eaeffb;
	--brand-2--2: #2849a0;
	--brand-2--1: #2d53b5;
	--brand-3-0: #ff9229;
	--brand-3-1: #ffa854;
	--brand-3-2: #ffbe7f;
	--brand-3-3: #ffd3a9;
	--brand-3-4: #ffe9d4;
	--brand-3-5: #fff4e9;
	--brand-3--2: #ff5110;
	--brand-3--1: #ff711d;
	--bg-white: #ffffff;
	--bg-fill: #f6f6f6;
	--bg-elements: #eeeeee;
	--bg-accent: #ff8a00;
	--bg-form: #3290ff;
	--line-separator: #d9d9d9;
	--line-dot-line: #c2c2c2;
	--text-base: #262626;
	--text-mid: #4d4d4d;
	--text-light: #808080;
	--text-pale: #b3b3b3;
	--text-link: #4535d4;
	--text-accent: #4535d4;
	--seat-unavailable: #eeeeee;
	--seat-hover-choosed: #5fca43;
	--seat-choosed-hover: #4ca236;
	--seat-economy-base: #aad1ff;
	--seat-business: #cbc4f5;
	--seat-premium: #e3d9bf;
	--seat-economy-front: #ffd15b;
	--seat-economy-extra-legroom: #cbc4f5;
	--seat-free: #a0dba2;
	--additional-aeroexpress: #e6494c;
	--additional-baggage: #4fcda0;
	--additional-business-lounge: #9a84dc;
	--additional-capsule-hotel: #65bfdb;
	--additional-ffp: #61579f;
	--additional-insurance: #7cb8ee;
	--additional-meal: #ff942d;
	--additional-priority-boarding: #e46f6f;
	--additional-sms: #f28d6d;
	--additional-seats: #37beed;
	--additional-checkin: #ffe601;
	--success-bg: #e7f7e3;
	--success-stroke-hover: #bfeab4;
	--success-icon: #5fca43;
	--success-text: #23aa00;
	--error-bg: #fdf2f2;
	--error-stroke-hover: #f3a7a7;
	--error-icon: #e02222;
	--error-text: #be1d1d;
	--recommend-bg: #ffecce;
	--recommend-stroke-hover: #ffc56d;
	--recommend-icon: #ff9e0c;
	--warning-bg: #fffdcc;
	--warning-stroke-hover: #fffa80;
	--warning-icon: #efea6c;
	--subscription-0: #ab53f0;
	--subscription--1: #923aeb;
	--subscription--2: #7921e7;
	--subscription-1: #bc75f3;
	--subscription-2: #cd98f6;
	--subscription-3: #ddbaf9;
	--subscription-4: #eeddfc;
	--subscription-5: #f6edfd;

	/* Custom */
	--common-blue: var(--brand-1-0);
	--common-orange: var(--brand-3-0);
	--dark-orange: var(--brand-3--1);
	--dark-gray: var(--text-base);
	--light-gray: var(--text-light);
	--base: var(--brand-1-0);
	--brand1: var(--brand-1-0);
	--brand1-4: var(--brand-1-4);
	--brand1-5: var(--brand-1-5);
	--success-20: var(--success-text);
	--cabinet-action--hover: var(--brand-1--1);
	--success-20: var(--success-text);
	--success90: var(--success-bg);
	--gray05: var(--bg-fill);

	--stepbar-background-color: var(--brand-1-0);

	--datepicker-current-selected-date-color: var(--text-base);
	--datepicker-directions-default-color: var(--text-base);
	--datepicker-day-period-background: var(--brand-1-5);

	--fareGroup-header-color: var(--text-mid);
	--fareGroup-upgrade-option-bg: var(--bg-fill);
	--fareGroup-recommended-border-color: var(--brand-3-0);

	--navigation-edit-background-color: transparent;
	--navigation-edit-icon-color: var(--brand-1-3);

	--base: var(--brand-1-0);
	--base15: var(--brand-1-0);
	--active: var(--brand-3-0);
	--active-30: var(--brand-3--1);

	--text: var(--text-base);
	--gray-line: var(--line-separator);

	--pricegraph-day: var(--brand-1-4);
	--seat-occupaied: var(--brand-1-0);
	--selected: var(--brand-1-0);
	--common-blue: var(--brand-1-0);

	--font: 'Montserrat';
}

@font-face {
	font-family: 'Montserrat';

	src: url(https://cdn.websky.aero/SZ/static/5e077c15f6e1d334dd4e9be62b28ac75.ttf) format('truetype');

	font-weight: 400;
}

@font-face {
	font-family: 'Montserrat';

	src: url(https://cdn.websky.aero/SZ/static/bdb7ba651b7bdcda6ce527b3b6705334.ttf) format('truetype');

	font-weight: 500;
}

@font-face {
	font-family: 'Montserrat';

	src: url(https://cdn.websky.aero/SZ/static/94fbe93542f684134cad1d775947ca92.ttf) format('truetype');

	font-weight: 300;
}

@font-face {
	font-family: 'Montserrat';

	src: url(https://cdn.websky.aero/SZ/static/ed86af2ed5bbaf879e9f2ec2e2eac929.ttf) format('truetype');

	font-weight: 700;
}

body {
	color: #353535;

	font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;

	font-family: var(--font), Helvetica, Arial, sans-serif !important;

	line-height: 1.15;

	background-color: #f6f6f6;
}

.MuiButton-root {
	font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
	font-family: var(--font), Helvetica, Arial, sans-serif !important;
}

#root {
	height: 100%;
}

@media screen and (max-width: 600px) {
	:root {
		--navigation-edit-icon-color: var(--white);
	}
}
