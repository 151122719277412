

.container-2nF7w {
	padding: 32px;

	border-radius: 20px;

	border: none;
}

.container-2nF7w,
.container-2nF7w:hover {
	box-shadow: none;
}

.title-3OrVB {
	margin-bottom: 20px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
	line-height: 1.3;
}

