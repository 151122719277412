.wrapper-3lYBJ {
	display: flex;
	flex-direction: row-reverse;

	max-width: 1100px;
}

.form-1trlN {
	flex: 1;
}

.hint-r1hPF {
	margin-right: 12px;
	margin-top: 0;
}

.hint-r1hPF,
.wrapper-3lYBJ .retrieveBooking-3mXA5 {
	flex: 1;
}

.wrapper-3lYBJ .retrieveBooking__title-2Delo {
	display: none;
}

button.checkinErrorBoundary__button-1BiM4,
button.checkinErrorBoundary__button-1BiM4:hover,
button.checkinErrorBoundary__button-1BiM4:focus,
button.checkinErrorBoundary__button-1BiM4:active {
	padding: 0 20px;

	color: var(--white);

	background: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.wrapper-3lYBJ {
		flex-direction: column;
		padding: 20px 12px;
	}
}
