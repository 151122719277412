

button.button-g4HTb {
	color: var(--brand-1-0);
}

button.close-1vwu_ {
	position: absolute;
	top: 32px;
	right: 32px;
	z-index: 1;

	width: 32px;
	min-width: 32px;
	height: 32px;
	min-height: 32px;
	padding: 0;

	border-radius: 50% !important;

	color: var(--text-light);

	background-color: transparent;
}

button.close-1vwu_:hover {
	color: var(--white);

	background: var(--brand-1-1);
}
