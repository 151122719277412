

button.decrease-3juHL,
button.increase-2Xf8- {
	background: var(--brand-1-5);
}

button.decrease-3juHL:hover,
button.increase-2Xf8-:hover {
	background: var(--brand-1-0);
}

button.decrease-3juHL:active,
button.increase-2Xf8-:active {
	background: var(--brand-1--1);
}

button.decrease-3juHL svg path,
button.increase-2Xf8- svg path {
	fill: var(--brand-1-0);
}

button.decrease-3juHL:hover svg path,
button.increase-2Xf8-:hover svg path {
	fill: var(--white);
}

button.decrease-3juHL:disabled,
button.increase-2Xf8-:disabled {
	background: var(--bg-fill);
}

button.decrease-3juHL:disabled svg path,
button.increase-2Xf8-:disabled svg path {
	fill: var(--line-dot-line);
}

.counter-2cOlD {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.counter_disabled-2G4XA {
	color: var(--text-pale);
}
