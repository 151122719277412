

.icon-1Niwb {
	color: var(--brand-1-0);
}

.baggageIcon_wrapper-3O1K2 {
	color: var(--brand-1-0);
}

.baggageIcon_wrapper-3O1K2 svg circle {
	fill: var(--brand-1-4);
}

.baggageIcon_wrapper-3O1K2 svg path:not(:last-child) {
	fill: var(--brand-1-0);
}

.name-397Vh {
	font-size: 13px;
	font-weight: 400;
	color: var(--text-base);
}

.size-2idek {
	font-size: 13px;
}
