

.group__label-3YGEx {
	display: block;

	font-weight: 700;
	white-space: nowrap;
}

.option-tzvzR {
	display: flex;
	align-items: center;

	min-height: 40px;
}

.option-tzvzR:hover {
	background: var(--brand-1-5);
}

.option__info-1roJd {
	width: 100%;
}

.option__label-ZbGVd {
	font-weight: 500;
	line-height: 1.3;
}

.option__code-3ycMn {
	width: 60px;

	color: var(--text-pale);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.allDirections-1J2bU button.allDirections__button-32uPD {
	border-radius: 4px;

	font-size: 12px;
}

.allDirections-1J2bU button.allDirections__button-32uPD:hover {
	background: var(--brand-1--1);
}

.dropdown__options-1zKTU {
	min-width: 230px;
}
