

.headerEquipmentItem__wrapper-vkBIi.headerEquipmentItem__active-woxGb {
	background-color: var(--brand-1-0);
}

.headerEquipmentItem__wrapper-vkBIi {
	color: var(--brand-1-0);

	background-color: var(--brand-1-5);
}

.baggagePassenger__name-2O9M2 {
	font-size: 16px;
	font-weight: 400;
	color: var(--text-base);
}

.baggageFooter__clear-P5eUF {
	color: var(--brand-1-0);
	font-size: 14px;
}

.baggageDialogHeader__categoryTitle-1OPfc {
	color: var(--brand-1-0);
}

.baggageDialogHeader__priceLabel-1OGkt.baggageDialogHeader__perFlight-3OICI {
	color: var(--success-text);
}

.baggageSegment__root-1sXhf {
	color: var(--text-base);

	background-color: var(--brand-1-5);
}

.baggageFooter__priceWrapper-1ZaqQ {
	color: var(--text-base);
}

.mobileBaggageSelector__root-3iXX2 {
	color: var(--brand-1-0);
}
