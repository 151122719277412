

.day_withPrice-3AkOp {
	width: 42px;
	height: auto;
	min-height: 44px;

	margin: 2px 1px 2px 0;
	padding: 6px 2px 5px;

	border-radius: 4px;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
}

.day_withPrice-3AkOp.day_selected-3ldiF {
	padding: 8px 2px 5px;

	background: var(--brand-1-0);
}

.day__price-3YhBh {
	color: var(--text-light);
	font-weight: 300;
	line-height: 1.3;
}

.day_today-TiTBS {
	margin-right: 1px;
	margin-left: 1px;

	border: 1px solid var(--brand-1-0);
}

.day_inPeriod-6GMa6 {
	padding: 8px 2px 5px;
}

.day_inPeriod-6GMa6.day_highlighted-2BraA div {
	font-weight: 500;
}

div.day-2zgHd.day_inPeriod-6GMa6,
div.day-2zgHd.day_weekend-qQh-O.day_inPeriod-6GMa6 {
	color: var(--text-pale);
}

div.day_hasPrice-29ZbW {
	color: var(--text-base);
	font-weight: 500;
	line-height: inherit;
}

div.day_startHover_withPrice-1yEtL,
div.day_endHover_withPrice-n1vSu {
	border-color: var(--brand-1-0);
}

.day_withPrice-3AkOp:not(.day_startHover_withprice-1FQTv):not(.day_endHover_withPrice-n1vSu):not(.day_selected-3ldiF):hover {
	border-color: var(--brand-1-0);
}
