

.root-3KBDH {
	order: -1;

	border-bottom: 1px solid #e3e3e3;

	color: var(--text-mid);
}

button.button-3Lk8w.button_checkin-3_Ap2 {
	background: var(--brand-3-0);
}
