@media (max-width: 1024px) and (min-width: 601px) {
	.controls-3txk3.controls_multiCity-RmEXu {
		position: relative;
		top: 0;

		margin-top: 4px;
		padding-right: 44px;
	}
}

@media (max-width: 1024px) {
	.controls-3txk3 {
		top: 48px;
		right: 0;

		padding-right: 0;
	}
}

@media screen and (max-width: 600px) {
	.controls-3txk3 {
		flex-direction: column;
	}
}
