h3.title-15v5t {
	color: var(--text-base);
}

.segments-1TCAO {
	background: none;
}

button.toolbar__button-2fr4L {
	position: relative;

	margin-left: auto;

	font-weight: 700;

	font-size: 18px;

	background: var(--brand-3-0);
}

button.toolbar__button-2fr4L:hover {
	background: var(--brand-3--1);
}

@media screen and (max-width: 600px) {
	h3.title-15v5t {
		margin-top: 10px;
		margin-bottom: 20px;

		font-size: 20px;
	}

	.toolbar-Pwpwl {
		display: flex;
		align-items: center;

		padding: 12px;

		box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
	}

	button.toolbar__button-2fr4L {
		font-size: 14px;
	}

	.container-GMqgy {
		position: relative;
		top: -10px;

		border-radius: 12px 12px 0 0;
	}
}
