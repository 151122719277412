

.airline-mMNeY {
	width: auto;
	max-width: 50%;

	color: var(--brand-1-0);
}

.airline__number-1qyjQ,
.airline__name-3qSAw {
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
}

.aircraft__name_active-2UJnb {
	color: var(--brand-1-0);

	cursor: pointer;
}
