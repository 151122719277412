

.wrapper-3Ynlc {
	color: var(--text-light);
}

.wrapper-3Ynlc:after {
	background: var(--line-separator);
}

.wrapper-3Ynlc:before {
	opacity: 1;
}

.active-290_B:after {
	background: var(--brand-1-0);
}

.label-raeUT {
	opacity: 1;
}
