

.dayWrapper-3ITLw {
	padding: 0;
}

.dayWrapper-3ITLw:not(:last-child) {
	border-right: 1px solid var(--line-separator);
}

.day-2pr2n {
	justify-content: center;
	min-height: 94px;

	transition: background 0.15s, border 0.15s;
}

.date-AvHNh {
	color: var(--text-mid);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.day-2pr2n.day_best-22WmQ .date-AvHNh {
	color: var(--success-text);
}

.day-2pr2n.day_notAvailable-KjJzr .date-AvHNh {
	color: var(--text-pale);
}

.date__dow-1XHQ_ {
	margin: 4px 24px;
	padding-bottom: 0;
}

.date__info-37Tiy {
	position: relative;
	top: 0;

	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.date__info-37Tiy:before {
	position: absolute;
	top: 50%;
	right: 25%;
	left: 25%;

	z-index: -1;

	border-bottom: 1px dashed var(--line-separator);
	content: '';
}

.date__info-37Tiy span {
	color: var(--text-pale);

	transition: background 0.15s;
}

.price-2ilMP {
	padding-left: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 24px;
	line-height: 1.3;
}

.day-2pr2n.day_best-22WmQ .price-2ilMP,
.day-2pr2n.day_selected-hXVTX .price-2ilMP {
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;
}

.day-2pr2n.day_best-22WmQ .price-2ilMP {
	color: var(--success-text);
}

.price__wrapper-5ilVX {
	flex-grow: 1;
	padding-bottom: 4px;
}

.dayWrapper-3ITLw:hover .day-2pr2n,
.dayWrapper-3ITLw:hover .date__info-37Tiy span {
	background: var(--brand-1-5);
}

.dayWrapper-3ITLw:hover .day-2pr2n {
	border-color: var(--brand-1-0);
}
