

.monthHeader-1RPeM {
	margin-bottom: 12px;
}

.monthHeader__select_month-3KyWi {
	color: var(--text-base);
}

.monthHeader__select_year-3oMhH {
	color: var(--text-pale);
}

.monthHeader__select_month-3KyWi,
.monthHeader__select_year-3oMhH {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}
